<template>
  <v-form
      v-bind="formHeader"
      v-model="isValid"
      :lazy-validation="lazyValidation"
      :ref="formRef"
      id="dialog_form"
      @submit.prevent>
    <v-card>
      <dialogTitle :title="dialogProps.dialogTitle" :result="result">
        <simplePopup
            :title="'Notice'"
            :type="'notice'"
            :content="'We are working on it, soon we will update!'"
            :visible="docNoticePopup"
            @close="docNoticePopup = false"></simplePopup>

        <v-btn
            icon dark @click="toggleMaximize()" v-if="!result['removeMaximize']">
          <v-icon v-if="!dialogFormat.maximized">mdi-window-maximize</v-icon>
          <v-icon v-else>mdi-window-restore</v-icon>
        </v-btn>

        <v-btn
            icon dark
            @click="$emit('update:attempt-close-dialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </dialogTitle>

      <formNotice
          v-if="typeof result['formNotice'] != 'undefined'" :result="result"></formNotice>
      <dialogContent :result="result">
        <slot :isValid="isValid" :result="result" :submitted="submitForm">
          <formTable
              :result="result"
              v-on="$listeners"
              @file-upload="registerFile(...arguments)"
              @revalidate="revalidate"
              @changeIsValid="changeIsValid"
              :valueLine="result.valueLine"
              :isValid="isValid"></formTable>
        </slot>
      </dialogContent>
      <slot
          :isValid="isValid" :result="result" name="fieldActions" :submitted="submitForm">
        <v-card-actions>
          <fieldsButtons
              :result="result"
              :isValid="isValid"
              v-on="$listeners"
              @submitted="submitForm(...arguments)"></fieldsButtons>
        </v-card-actions>
      </slot>
    </v-card>
  </v-form>
</template>
<script>
import {getdefaultFormHeader, getRandomInt} from '@/js/helper.js';
import formNotice from '@/commonComponents/formNotice.vue';
import formTable from '@/commonComponents/formTable.vue';
import fieldsButtons from '@/commonComponents/fieldsButtons.vue';
import dialogTitle from '@/commonComponents/dialogTitle.vue';
import dialogContent from '@/commonComponents/dialogContent.vue';
import simplePopup from '@/commonComponents/simplePopup.vue';
import requests from '@/js/requests';

export default {
  components: {
    formTable,
    fieldsButtons,
    formNotice,
    dialogTitle,
    dialogContent,
    simplePopup,
  },
  data: function () {
    return {
      formHeader: getdefaultFormHeader(this.result),
      isValid: true,
      docNoticePopup: false,
      dialog: false,
      formRef: '',
      uploadedFiles: {},
    };
  },
  created() {
    this.formRef = 'form' + getRandomInt(0, 1000);
    this.updateRef(this.result);
    this.$nextTick(function () {
      this.$refs[this.formRef].validate();
    });
  },
  watch: {
    result(newVal) {
      this.updateRef(newVal);
    },
  },
  props: ['result'],
  computed: {
    lazyValidation() {
      let lazyValidation = true;
      if (typeof this.result.lazyValidation != 'undefined') {
        lazyValidation = this.result.lazyValidation;
      }
      return lazyValidation;
    },
    dialogProps() {
      let dialogProps = {dialogFormat: {}};
      if (typeof this.result.json != 'undefined') {
        dialogProps = this.result.json;
      }
      return dialogProps;
    },
    dialogFormat() {
      let dialogFormat = {};
      if (typeof this.dialogProps.dialogFormat != 'undefined') {
        dialogFormat = this.dialogProps.dialogFormat;
      }
      return dialogFormat;
    },
  },
  methods: {
    updateRef(newVal) {
      let localResult = {...newVal};
      if (typeof localResult.formRef == 'undefined') {
        this.$nextTick(() => {
          this.$set(localResult, 'formRef', this.formRef);
          this.$set(localResult, 'formRefObject', this.$refs[this.formRef]);
          this.$store.commit('dialogResultChanged', localResult);
        });
      }
    },
    registerFile(fileName, file) {
      //console.log('uploaded updating', fileName, file);
      this.$set(this.uploadedFiles, fileName, file);
    },
    toggleMaximize() {
      let localResult = {...this.result};
      let maximized = !this.dialogFormat.maximized;
      this.$set(localResult.json.dialogFormat, 'maximized', maximized);
      this.$emit('dialog-result-changed', localResult);
      if (typeof this.result['additionlFormAtts'] != 'undefined' && typeof this.result['additionlFormAtts'] !== 'undefined') {
        let dialogType = this.result['additionlFormAtts']['data-callingFunction'];
        let width = this.dialogFormat.width;
        let url = this.result.tableUrl;

        requests.frameworkAxiosRequest({
          method: 'post',
          url: url,
          data: {
            function: 'setDialogWidth',
            dialogType: dialogType,
            width: width,
            maximized: maximized,
          },
        })
            .then(function () {
              //handle success
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
      }
    },
    revalidate() {
      let value = this.$refs[this.formRef].validate();
      this.$set(this.result, 'valid', value);
      this.isValid = value;
      // console.log("revalidating",value);
      this.$store.commit('dialogResultChanged', this.result);
    },
    changeIsValid(value) {
      //  console.log("changeIsValid", value);
      if (this.$refs[this.formRef].validate()) {
        this.isValid = value;
      }
    },
    submitForm(functionName) {
      //console.log(this.$refs[this.formRef].validate());
      if (this.$refs[this.formRef].validate()) {
        this.ajaxSubmit(
            functionName,
            this.$el,
            this.result.tableUrl,
            this.result,
            this.uploadedFiles,
            this,
        );
        if (
            typeof this.result.closeDialog != 'undefined' &&
            this.result.closeDialog === true
        ) {
          this.$emit('close-dialog');
        }
      } else {
        console.log('not valid');
        this.$store.commit('dialogButtonsLoading', false);
        this.isValid = false;
      }
    },
  },
};
</script>
<style>
/* it do magic to avoid second Scroll*/
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100% !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"buttonCollectionContainer",staticStyle:{"width":"100%"}},[(_vm.divider == true)?_c('div',[_c('v-divider')],1):_vm._e(),_c('v-row',_vm._b({class:_vm.customClass,attrs:{"no-gutters":""}},'v-row',_vm.rowAttr,false),[(_vm.versioninput == true)?_c('v-col',{staticClass:"mr-2",staticStyle:{"height":"40px"},attrs:{"cols":"12","md":"4"}},[_c('primaryTextfield',{attrs:{"label":"Commit Message","type":"text","name":"c_change_message","fieldAttrInput":{
          placeholder: _vm.language.componentLabel.phVersion,
          class: 'versionChangeMessage tPlus',
        },"fieldAttributes":{ value: _vm.versioningValue },"templateContent":_vm.result,"hideDetails":true}})],1):_vm._e(),_c('v-col',_vm._b({staticClass:"d-flex"},'v-col',_vm.colAttr,false),[_c('input',{key:"function",attrs:{"type":"hidden","name":"function"},domProps:{"value":_vm.functionName}}),_vm._l((_vm.result['function']),function(functionElement,name){return _c('div',{key:name},[(functionElement['template'])?_c('dynamicComponent',_vm._g({attrs:{"type":functionElement['template'],"result":_vm.result,"additional":{
            isValid: _vm.isValid,
            calcDisabled: !_vm.isValid || _vm.loading,
            loading:
              _vm.loading &&
              _vm.isValid &&
              functionElement['function'] == _vm.functionName,

            localLoading: _vm.loading,
            functionElement: functionElement,
            id: _vm.getId(functionElement.function),
          }},on:{"click":function($event){$event.stopPropagation();return _vm.setFunctionName(functionElement['function'])}}},_vm.$listeners)):(functionElement['selfLink'])?_c('primaryButton',{class:typeof functionElement['className'] != 'undefined'
              ? functionElement['className']
              : '',attrs:{"label":functionElement['functionName'],"icon":_vm.ICONS_MAPPING[functionElement['selfLinkName']],"color":_vm.colorSelect(functionElement),"elevation":"1","attributesArray":{
            id: _vm.getId(functionElement.selfLinkName),
          }},on:{"click":function($event){$event.stopPropagation();return _vm.redirectURL(functionElement['selfLink'])}}}):_c('primaryButton',{staticClass:"mr-2",attrs:{"attributesArray":{
            ...functionElement['attributesArray'],
            id: _vm.getId(functionElement.function),
          },"type":"button","value":functionElement['functionName'],"label":functionElement['functionName'],"icon":typeof functionElement['customIconName'] != 'undefined'
              ? _vm.ICONS_MAPPING[functionElement['customIconName']]
              : _vm.ICONS_MAPPING[functionElement['function']],"color":_vm.colorSelect(functionElement),"disabled":!_vm.isValid || _vm.loading,"loading":_vm.loading && _vm.isValid && functionElement['function'] == _vm.functionName,"shrink":typeof functionElement['shrink'] != 'undefined'
              ? functionElement['shrink']
              : ''},on:{"click":function($event){$event.stopPropagation();return _vm.setFunctionName(functionElement['function'])}}})],1)}),_vm._l((_vm.result.additionalFieldsButtons),function(value,type){return _c('div',{key:type,staticClass:"mr-2 d-inline-block"},[_c('dynamicComponent',{attrs:{"type":type,"additional":{ buttonAttrs: value }}})],1)}),(typeof _vm.result['closeButton'] != 'undefined')?_c('cancelButton',{attrs:{"result":_vm.result,"id":_vm.getId('cancel')},on:{"update:attempt-close-dialog":function($event){return _vm.closeDialog()}}}):_vm._e(),(typeof _vm.routerLink != 'undefind' && _vm.routerLink != null)?_c('router-link',{class:_vm.routerLink.class,attrs:{"to":_vm.routerLink.to}},[_vm._v(_vm._s(_vm.routerLink.text)+" ")]):_vm._e(),(typeof _vm.result['stencil']!=='undefind' && _vm.result['stencil'] ===true && _vm.$route.query.f2!=='testInfo')?_c('div',{staticStyle:{"display":"flex"}},[_c('v-switch',{staticClass:"mt-1 ml-3",attrs:{"label":"Save as Stencil","disabled":_vm.disabled},model:{value:(_vm.enableStencil),callback:function ($$v) {_vm.enableStencil=$$v},expression:"enableStencil"}}),_c('HelpButton',{attrs:{"id":"stencil_switch"}})],1):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
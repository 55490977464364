<template>
  <primaryButton
      label="Cancel"
      icon="mdi-cancel"
      @click="onCancel"
      type="button"
      color="secondary"
      :id="id"
  />
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import primaryButton from "@/commonComponents/primaryButton.vue";

defineProps({
  id: { type: String, required: true }
});

const emit = defineEmits();

const onCancel = () => {
  emit('update:attempt-close-dialog');
};
</script>